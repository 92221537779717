<script setup lang="ts">
import { type ILeaseVariation, useBacklink } from '@register'
import { useAmendmentFieldSubmit } from '@register/components/Amendment/Edit/composables/useAmendmentFieldSubmit'

// PROPS
const props = withDefaults(
  defineProps<{
    variation: ILeaseVariation
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

// BACK-LINKING
const { selectedCopiedText, onCopyFromDocument } = useBacklink()

// FIELD SUBMIT
const { onSubmit, fieldValue } = useAmendmentFieldSubmit(
  toRef(props, 'variation'),
)

const field = computed(() => ({
  ...props.variation.leaseField,
  value: {
    value: props.variation.value,
    bounds: props.variation.bounds,
    isApproved: props.variation.isApproved ?? false,
    dates: props.variation.dates,
    originalDates: props.variation.originalDates,
  },
}))

const classes = computed(() => {
  // TODO: BE should return the large property
  // if (props.large) {
  //   return 'lg:col-span-2 w-full group relative flex w-full flex-col data-[schema=table]:col-span-full '
  // }

  return 'w-full group relative flex w-full flex-col data-[schema=table]:col-span-full data-[schema=maps]:col-span-full data-[schema=exclusiveCheckbox]:col-span-full'
})

// inner-class="formkit-disabled:ring-2 formkit-disabled:ring-primary formkit-disabled:bg-primary/5"
// input-class="formkit-disabled:!text-gray-200"
// outer-class="flex-auto formkit-disabled:!opacity-100"
</script>

<template>
  <FormKit
    :id="variation.leaseField.id"
    v-model="fieldValue"
    type="lease-document"
    :name="variation.leaseField.id"
    :field="field"
    :label="variation.leaseField.name"
    :selected-text="selectedCopiedText"
    :validation="[variation.isApproved ? [] : ['required']]"
    :can-approve="false"
    :side-actions="false"
    :validation-messages="{
      required: 'Set a new value to ' + title(variation.leaseField.id),
    }"
    :help="`Add a new value for ${variation.leaseField.name}`"
    dirty-behavior="compare"
    :outer-class="classes"
    @copy-from-document="onCopyFromDocument(variation.leaseField)"
    @input="onSubmit"
  />
</template>
